.form__container_edit{
    background-color: #f5f5f5e5;
    float: left;
    min-width: 10%;
    width: 90%;
    height: 80%;
    margin-left: 5% ;
    margin-top: 5% ;
    text-align: center;
    position: inherit;
    padding: 3.5em 3em;
    border-radius: 10px;
  
    display: block;
}
.contenedor_boton_edit{
    display:block;
    height: 20%;
    box-shadow: #291f88;
}

.edit__title{
    font-size: 2.5rem;
    margin-bottom: 0.5em;
    margin-top: -45px;
    color: #0a2047e5;
}
.edit__sub-title {
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 0.5em;
    margin-top: 10px;
  }
.botton_Edit{
    
        text-align: center;
        width: 9.0rem;
        background: #0a1841;
        border: none;
        padding: 12px;
        color: #e3e5e9;
        margin: 3px 0;
        border-radius: 20px;
        font-size: 16px;
}
#imprime{
    
  text-align: center;
  width: 9.0rem;
  background: #0a1841;
  border: none;
  padding: 12px;
  color: #e3e5e9;
  margin: 3px 0;
  border-radius: 20px;
  font-size: 16px;
}

#imprime:hover{
    
 
  background: green;
  
  
}

#edita{
    
  text-align: center;
  width: 9.0rem;
  background: #0a1841;
  border: none;
  padding: 12px;
  color: #e3e5e9;
  margin: 3px 0;
  border-radius: 20px;
  
  font-size: 16px;
}

#edita:hover{
    
 
  background: green;
  
  
}
.botton_Edit:hover{
    
    
    background: #e22c0c;
    color:white
    borde
   
}
.edit__footer{
    text-align: center;
  font-size: smaller;
  height: auto;
  width: 100%;
  margin-top: 1%;
  font-family: 'Roboto', sans-serif;
  color: black;
}